<template>
  <base-section
    id="affiliates"
    space="50">
    <v-container>
      <base-section-heading title="Kantor Pemasaran" />
      <v-card>
      <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Cari Kantor Pemasaran"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
      <v-data-table
	    :headers="headers"
	    :items="branch"
	    :search="search"
	    item-key="branch_name"
	    class="elevation-1"
	  >
	  <template v-slot:item.branch_address="{ item }">
        {{item.branch_address}}
       </template>
	  </v-data-table>
	</v-card>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNetwork',
    data: () => ({
      search: '',	
      searchautoshop: '',	
      branch: [],
      autoshop: [],
      headers: [
        {
          text: 'Kantor',
          align: 'start',
          value: 'branch_name',
          class: "primary white--text body-1",
          width: '20%'
        },
        { text: 'Alamat', value: 'branch_address',class: "primary white--text body-1" },
        { text: 'Telepon', value: 'branch_phone_1',class: "primary white--text body-1", width: '20%' },
        { text: 'PIC', value: 'branch_pic',class: "primary white--text body-1", width: '20%' },
      ],
    }),
    created () {
    	this.Branch()
    },
    methods: {
      	Branch () {
          let branches = [
              {"branch_name":"BANDUNG","branch_address":"NA","branch_phone_1":"NA","branch_pic":"NA"},
              {"branch_name":"SURABAYA","branch_address":"NA","branch_phone_1":"NA","branch_pic":"NA"},
              {"branch_name":"SEMARANG","branch_address":"NA","branch_phone_1":"NA","branch_pic":"NA"},
              {"branch_name":"MEDAN","branch_address":"NA","branch_phone_1":"NA","branch_pic":"NA"},
              ]
          this.branch = branches
        },
    }
  }
</script>
<style>
tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>